import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_flipbox()
})

const dhsv_vc_flipbox = () => {
  const equalHeight = (innerNode, parentNode) => {
    let highestBox
    if (innerNode.length > 1) {
      highestBox = 0
    }
    innerNode.each(function() {
      if ($(this).outerHeight() > highestBox) {
        highestBox = $(this).outerHeight()
      }
    })
    innerNode.closest(parentNode).height(highestBox)
  }

  $('.dhsv_vc_flipbox').each(function() {
    equalHeight($('.js-inner'), $('.js-flip'))
    $('.js-flip-front, .js-flip-back').css('position', 'absolute')
  })
}
