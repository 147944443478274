import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class TeaserSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  render() {
    const { slides } = this.props
    if (!slides) return null

    //eslint-disable-next-line
    const Arrow = ({ currentSlide, slideCount, ...props }) => {
      return <div {...props} />
    }

    const slickSettings = {
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,
      dots: true,
      infinite: true,
      pauseOnHover: false,
      pauseOnFocus: false,
      autoplay: true,
      autoplaySpeed: 5000,
      prevArrow: (
        <Arrow>
          <i className="fa fa-angle-left" />
        </Arrow>
      ),
      nextArrow: (
        <Arrow>
          <i className="fa fa-angle-right" />
        </Arrow>
      ),
      customPaging: () => <span />,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    }

    return (
      <div className="slider">
        <Slider {...slickSettings}>
          {slides.map((slide, i) => (
            <div className="slide" key={i}>
              <a href={slide.link}>
                <div
                  className="img"
                  style={{ backgroundImage: 'url(' + slide.image + ')' }}
                />
              </a>
              <h3>
                <a
                  href={slide.link}
                  dangerouslySetInnerHTML={{ __html: slide.title }}
                />
              </h3>
              <p>{slide.branche}</p>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

TeaserSlider.propTypes = {
  slides: PropTypes.array,
}

export default TeaserSlider
