// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_emptySpace.js'; import './autoload/_object-fit.js'; import './autoload/_slick.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/anchor/main.js'; import '../vc_elements/banners_slider/main.js'; import '../vc_elements/blog_overview/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/contact_block/main.js'; import '../vc_elements/cta/main.js'; import '../vc_elements/customerlist/main.js'; import '../vc_elements/download_link/main.js'; import '../vc_elements/download_modal/main.js'; import '../vc_elements/flipbox/main.js'; import '../vc_elements/googlemap/main.js'; import '../vc_elements/page_teaser/main.js'; import '../vc_elements/parallax_section/main.js'; import '../vc_elements/project_search/main.js'; import '../vc_elements/projects_grid/main.js'; import '../vc_elements/slider_container/main.js'; import '../vc_elements/tab_content/main.js'; import '../vc_elements/team/main.js'; import '../vc_elements/teaserslider/main.js'; import '../vc_elements/template_element/main.js'; import '../vc_elements/testimonial_slider/main.js'; import '../vc_elements/video/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
