import $ from 'jquery'

$(document).ready(dhsv_vc_contact_block())

function dhsv_vc_contact_block() {
  $('.js-contact-modal').on('shown.bs.modal', function() {
    $('.js-contact-btn').click(function(e) {
      e.preventDefault()
    })
    $('.js-contact-btn').trigger('focus')
  })
}
