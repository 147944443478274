import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Component from './component'
import { updatePoints, updateActiveStatus } from '../../../redux/modules/anchor'

export default connect(
  ({ anchor: { lastActive } }) => ({ lastActive }),
  dispatch =>
    bindActionCreators(
      {
        updatePoints,
        updateActiveStatus,
      },
      dispatch
    )
)(Component)
