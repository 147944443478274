const initialObject = {
  lastActive: '',
  points: [],
}
export const UPDATE_POINTS = 'pta002/anchor/UPDATE_POINTS'
export const UPDATE_ACTIVE_STATUS = 'pta002/anchor/UPDATE_ACTIVE_STATUS'

export const updatePoints = (id, title) => ({
  type: UPDATE_POINTS,
  id,
  title,
})

export const updateActiveStatus = id => ({
  type: UPDATE_ACTIVE_STATUS,
  id,
})

export default (state = initialObject, action) => {
  switch (action.type) {
    case UPDATE_POINTS:
      return {
        ...state,
        points: [
          ...state.points,
          {
            id: action.id,
            title: action.title,
          },
        ],
      }
    case UPDATE_ACTIVE_STATUS:
      return {
        ...state,
        lastActive: state.lastActive !== action.id ? action.id : '',
      }
    default:
  }
  return state
}
