import $ from 'jquery'

$(document).ready(dhsv_vc_cta())

function dhsv_vc_cta() {
  var dhsv_vc_event_box_height = 0
  var dhsv_vc_event_box_height_elm = 0
  var match_elms = 0

  $('.vc_row').each(function() {
    match_elms = $(this).find('.dhsv_vc_cta')

    match_elms.each(function() {
      dhsv_vc_event_box_height_elm = $(this).outerHeight()
      if (dhsv_vc_event_box_height_elm > dhsv_vc_event_box_height) {
        dhsv_vc_event_box_height = dhsv_vc_event_box_height_elm
      }
    })

    match_elms.each(function() {
      $(this).css('height', dhsv_vc_event_box_height + 'px')
    })
  })

  //prevent scrolling when modal is open
  var element = $(this).find('.dhsv_vc_cta')
  var modal_open = element.find('.modal.show')
  if (modal_open) {
    $('html').css('overflow-x', 'unset')
  } else {
    //
  }
}
