import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_download_modal()
})

function dhsv_vc_download_modal() {
  $('.dhsv_vc_download_modal').each(function() {
    $('.dhsv_vc_download_modal button.close').click(function() {
      location.reload()
    })
  })
}
