import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class TestimonialSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  render() {
    const { slides } = this.props
    if (!slides) return null

    //eslint-disable-next-line
    const Arrow = ({ currentSlide, slideCount, ...props }) => {
      return <div {...props} />
    }

    const slickSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      fade: true,
      infinite: true,
      pauseOnHover: false,
      pauseOnFocus: false,
      autoplay: true,
      autoplaySpeed: 5000,
      customPaging: () => <span />,
    }

    return (
      <div className="slider">
        <Slider {...slickSettings}>
          {slides.map((slide, i) => (
            <div className="slide" key={i}>
              <p className="testimonial">{slide.testimonial}</p>
              <div className="wrapper">
                <i className={slide.icon} />
                <span />
                <p className="employee_info">{slide.employee_info}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

TestimonialSlider.propTypes = {
  slides: PropTypes.array,
}

export default TestimonialSlider
