/* Don't forget to add string 'products' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  data: [],
}

const LOAD_PROJECTS_DATA = 'LOAD_PROJECTS_DATA'
const LOAD_PROJECTS_DATA_SUCCESS = 'LOAD_PROJECTS_DATA_SUCCESS'
const LOAD_PROJECTS_DATA_FAILURE = 'LOAD_PROJECTS_DATA_FAILURE'
const SET_PROJECTS_FIRST_PAGE = 'SET_PROJECTS_FIRST_PAGE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_PROJECTS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_PROJECTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: [...payload],
      }

    case LOAD_PROJECTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case SET_PROJECTS_FIRST_PAGE:
      return {
        ...state,
        data: [...payload],
      }

    default:
      return state
  }
}

export const loadProjects = (queryVars, activePage, postType) => dispatch => {
  dispatch({ type: LOAD_PROJECTS_DATA })
  // Rest API Params
  const params = {
    query: queryVars,
    page: activePage,
    post_type: postType,
  }

  const format = window.siteLang.format
  let apiBaseUrl = WP_API_BASE
  if (parseInt(format) === 3) {
    params.lang = window.siteLang.current
  } else if (window.siteLang.current !== window.siteLang.default) {
    apiBaseUrl = '/' + window.siteLang.current + apiBaseUrl
  }

  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      params.hasOwnProperty(key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }

  return fetch(`${apiBaseUrl}projects${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload,
        type: LOAD_PROJECTS_DATA_SUCCESS,
      })

      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_PROJECTS_DATA_FAILURE,
      })
    })
}

export const setFirstPosts = payload => dispatch => {
  dispatch({
    payload,
    type: SET_PROJECTS_FIRST_PAGE,
  })
}
