import React from 'react'
import PropTypes from 'prop-types'

class AnchorMenu extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { lastActive, points } = this.props
    return (
      <div className={`--menu`}>
        <div className="nav">
          {points.map((point, i) => (
            <div key={i}>
              <a className="btn-outline" href={`#${point.id}`}>
                <span>{point.title}</span>
              </a>
            </div>
          ))}
        </div>
        <ul
          className={lastActive === '' || lastActive === '--end' ? 'hide' : ''}
        >
          {points.map((point, i) => (
            <li key={i} className={point.id === lastActive ? 'active' : ''}>
              <a href={`#${point.id}`}>
                <span>{point.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

AnchorMenu.propTypes = {
  lastActive: PropTypes.string,
  points: PropTypes.array,
}

export default AnchorMenu
