import $ from 'jquery'
import { WP_API_BASE } from '../../scripts/shared/constants'

$(document).ready(function() {
  dhsv_vc_project_search()
})

const getProjectLink = function(id, form) {
  fetch(`${WP_API_BASE}projectlink?id=${id}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      } else {
        form
          .next('.js-no-found')
          .hide()
          .fadeIn()
      }
      throw new Error(response.statusText)
    })
    .then(link => {
      if (link === false) {
        form
          .next('.js-no-found')
          .hide()
          .fadeIn()
      } else {
        window.location.href = link
      }
      return link
    })
    .catch(error => {
      form
        .next('.js-no-found')
        .hide()
        .fadeIn()
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
    })
}

function dhsv_vc_project_search() {
  $('.dhsv_vc_project_search').each(function() {
    $('.js-id-search', $(this)).on('submit', function(e) {
      e.preventDefault()
      const val = $('[name="s-id"]', $(this)).val()
      if (val !== '') {
        getProjectLink(val, $(this))
      }
    })
  })
}
