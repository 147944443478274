import $ from 'jquery'

$(document).ready(dhsv_vc_customerlist())

function dhsv_vc_customerlist() {
  $('.dhsv_vc_customerlist .expand').on('click tap', function() {
    const $this = $(this)
    const $parent = $this.parent()
    if ($parent.hasClass('open')) {
      $this.prev().animate(
        {
          height: $parent.data('height'),
        },
        500
      )
      $parent.removeClass('open')
    } else {
      $this.prev().animate(
        {
          height: $(this)
            .prev()
            .get(0).scrollHeight,
        },
        500,
        function() {
          $this.prev().height('auto')
        }
      )
      $parent.addClass('open')
    }
  })
}
