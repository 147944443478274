import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_slider_container()
})

function dhsv_vc_slider_container() {
  $('.dhsv_vc_slider_container').each(function() {
    $(this).slick({
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
    })
  })
}
