import React from 'react'
import PropTypes from 'prop-types'

class AnchorPoint extends React.Component {
  constructor(props) {
    super(props)
    this.pointRef = React.createRef()
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    const { updatePoints, id, title } = this.props
    if (id !== '--end') {
      updatePoints(id, title)
    }
  }

  handleScroll() {
    const { updateActiveStatus, lastActive, id } = this.props
    const wScroll = window.pageYOffset
    const wInnerHeight = window.innerHeight
    const bounds = this.pointRef.getBoundingClientRect()
    const wCalc = id !== '--end' ? wScroll : wScroll + wInnerHeight / 2
    if (id !== lastActive && lastActive !== '--end') {
      if (bounds.top + wScroll - 81 <= wCalc) {
        updateActiveStatus(id)
      }
    } else {
      if (bounds.top + wScroll - 81 > wCalc) {
        updateActiveStatus('')
      }
    }
  }

  render() {
    const { id } = this.props
    return (
      <div
        id={id}
        ref={pointRef => {
          this.pointRef = pointRef
        }}
        className="--point"
      />
    )
  }
}

AnchorPoint.propTypes = {
  updatePoints: PropTypes.func,
  updateActiveStatus: PropTypes.func,
  lastActive: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
}

export default AnchorPoint
