import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import archive from './archive'
import anchor from './anchor'

const appReducer = combineReducers({
  browser,
  anchor,
  location: router,
  archive,
})

export default appReducer
