import cssVars from 'css-vars-ponyfill'
import '@fancyapps/fancybox'
import WOW from 'wow.js'

const dhsvCommon = {
  stickyBanner() {
    let scrollTop
    const body = $('body')
    const distance = 100

    function handleScroll() {
      scrollTop = $(window).scrollTop()

      if (scrollTop < distance) {
        body.removeClass('sticky-nav')
      } else {
        body.addClass('sticky-nav')
      }
    }

    handleScroll()
    $(window).on('scroll', this.throttled(10, handleScroll))
  },
  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  },
  setBodyWidth() {
    cssVars({
      variables: { '--body-width': document.body.clientWidth + 'px' },
    })
  },
  smoothScroll(link, e) {
    let destination = 0

    if (e) e.preventDefault()
    var hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      destination = $(hash).offset().top - 75
      history.replaceState(null, null, hash)
    }

    $('html, body').animate(
      {
        scrollTop: destination,
      },
      600
    )

    $(window).trigger('hashchange')
  },
  searchIsEnabled: false,
  toggleSearch() {
    this.searchIsEnabled = !$('.js-search-toggle').hasClass('is-opened')
    $('.js-search-toggle')[(this.searchIsEnabled ? 'add' : 'remove') + 'Class'](
      'is-opened'
    )
    $('.js-searchform')['fade' + (this.searchIsEnabled ? 'In' : 'Out')](300)
  },
  tooltipInit() {
    $('.tooltip-link').on('mouseenter', function() {
      $(this)
        .next('.tooltip')
        .show()
    })
    $('.tooltip-link').on('mouseout', function() {
      $(this)
        .next('.tooltip')
        .hide()
    })
  },
  primaryMenuInit() {
    $('body')
      .on('click', '.js-nav-primary-toggler', function() {
        $(this).toggleClass('active')
        $('.js-nav-primary')
          .slideToggle(300)
          .toggleClass('is-open')
      })
      .on('click', '.js-submenu-toggle', function() {
        $(this)
          .next('.sub-menu')
          .slideToggle(300)
      })
  },
  scrollToTop() {
    const toTopButton = $('#toTop')
    toTopButton.click(function(e) {
      e.preventDefault()
      $('html, body').animate({ scrollTop: 0 }, 'slow')
      return false
    })
    $(window).scroll(function() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        toTopButton.css('display', 'flex')
      } else {
        toTopButton.css('display', 'none')
      }
    })
  },
  vowAnimation() {
    const wow = new WOW({
      boxClass: 'wpb_animate_when_almost_visible',
      animateClass: 'animated',
      offset: 0,
      mobile: false,
      live: true,
      scrollContainer: null,
    })
    wow.init()
  },
  initSinglePostRelatedSlider() {
    if ($('#dhsv-related-posts-slider').length) {
      $('#dhsv-related-posts-slider').slick({
        dots: false,
        infinite: true,
        prevArrow:
          '<button type="button" class="slick-prev"><i class="fal fa-angle-left"></i></button>',
        nextArrow:
          '<button type="button" class="slick-next"><i class="fal fa-angle-right"></i></button>',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ],
      })
    }
  },
  init() {
    this.stickyBanner()
    this.setBodyWidth()
    this.primaryMenuInit()
    this.scrollToTop()
    this.vowAnimation()
    this.initSinglePostRelatedSlider()

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }

    $('.js-search-toggle').on('click', e => {
      e.preventDefault()
      this.toggleSearch()
    })

    const bannerSearch = $('.js-banner-search')
    $(document).mouseup(e => {
      if (
        !bannerSearch.is(e.target) &&
        bannerSearch.has(e.target).length === 0 &&
        this.searchIsEnabled
      ) {
        this.toggleSearch()
      }
    })
    this.tooltipInit()

    $('.prettyphoto').fancybox()
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
    dhsvCommon.onResize()

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      function(e) {
        dhsvCommon.smoothScroll($(this).attr('href'), e)
      }
    )
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
