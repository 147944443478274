import React from 'react'
import AnchorPoint from '../point'

class AnchorEnd extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <AnchorPoint id={'--end'} />
  }
}

export default AnchorEnd
